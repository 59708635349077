import axios from "axios";

const axiosInstance = axios.create({
    // withCredentials: true,
    // baseURL: 'http://localhost:5000',
    baseURL: 'https://onedollardonation.onrender.com',
})

const generateAuthHeaderObj = (authToken) => {
    return {
        headers: {
            'auth-token': authToken
        }
    }
}

export const joinOneDollarCampaign = async (data) => {
    return await axiosInstance.post('/api/one-dollar/', data)
}

export const getUsernames = async () => {
    return await axiosInstance.get('/api/one-dollar/usernames')
}

export const loginUser = async (data) => {
    return await axiosInstance.post('/api/one-dollar/login', data)
}

export const getAuthUserStatus = async (authToken) => {
    return await axiosInstance.get('/api/one-dollar/auth-status', generateAuthHeaderObj(authToken))
}

export const makeNewDonation = async (data) => {
    return await axiosInstance.post('/api/one-dollar/new-donation', data)
}

export const getDonations = async (authToken) => {
    return await axiosInstance.get('/api/one-dollar/donations', generateAuthHeaderObj(authToken))
}

export const getDonationsForAdmin = async (authToken) => {
    return await axiosInstance.get('/api/one-dollar/admin-donations', generateAuthHeaderObj(authToken))
}