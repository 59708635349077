import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import styles from './styles.module.css';
import { CountryDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import Select from 'react-select';
import { supportedCurrencies } from "../../services/SupportedCurrencies";
import { Countries } from "../../services/Countries";
import swal from "@sweetalert/with-react";
import { submitToApi } from "../../services/Helpers";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
import { bankAccounts, validateEmail } from "../../utils/util";
import { getUsernames, makeNewDonation } from "../../services/oneDollarServices";
import { useParams, useSearchParams } from "react-router-dom";


const OneDollarDonationFormScreen = () => {

    const [ pageLoading, setPageLoading ] = useState(true);
    const [ usernameFound, setUsernameFound ] = useState(false);
    const { username } = useParams();
    const [ details, setDetails ] = useState({
        firstName: '',
        lastName: '',
        email: '',
        country: '',
        phone: '',
        currency: '',
        amount: '1',
    });
    const [ countryString, setCountryString ] = useState('');
    const [ btnDisabled, setBtnDisabled ] = useState(false);
    const [ show, setShow ] = useState(false);
    const [ searchParams, setSearchParams ] = useSearchParams();

    useEffect(() => {

        document.title = "Donate | 1Dollar"
        const successParam = searchParams.get("success");

        getUsernames().then(res => {
            // console.log(res.data)
            !successParam && setPageLoading(false);
            const usernames = res.data.map(item => item.username);
            const foundUsername = usernames.find(item => item === username);
            if (!foundUsername) return

            setUsernameFound(true);
        }).catch(err => {
            !successParam && setPageLoading(false)
        })

        if (successParam) {
            const [ firstName, lastName, country, amountDonated, phone, email, currency ] = [
                searchParams.get('firstName'),
                searchParams.get('lastName'),
                searchParams.get('country'),
                searchParams.get('amount'),
                searchParams.get('phone'),
                searchParams.get('email'),
                searchParams.get('currency'),
            ]

            makeNewDonation({
                firstName,
                lastName,
                country,
                amountDonated,
                phone,
                email,
                currency,
                usernameUsed: username,
            }).then(res => {
                // console.log(res.data);
                swal("Thank you", "Your donation has been received and your details have been saved", "success");
            }).catch(err => {
                console.log(err);
                swal("Error", err.response ? err.response.data : err.message, "error");
            })
        }

        if (searchParams.get("canceled")) {
            swal(
                "failed", "Donation canceled, please try again", "error"
            );
        }
    
    }, [])

    useEffect(() => {

        if (details.country.length < 1) return

        setCountryString(countryCode(details.country))
    
    }, [details.country])


    const handleDetailChange = (name, value, numericValue=false) => {
        const updatedValue = numericValue ?
            value.replace(/\D/g, "") 
            :
        value;

        setDetails((prevDetails) => {
            return {
                ...prevDetails,
                [name]: updatedValue,
            }
        })
    }

    
    const countryCode = (countryName) => {
        return Countries.find(({ Code, Name }) => Name.toLowerCase() === countryName.toLowerCase())?.Code.toLowerCase()
    }
    
    const config = {
        public_key: "FLWPUBK-dc1cc869358a6b22903296b51a1c827a-X",
        tx_ref: Date.now(),
        amount: Number(details.amount),
        currency: details.currency.value,
        payment_options: "card,mobilemoney,ussd",
        // specified redirect URL
        redirect_url: `${window.location.origin}/1dollar/${username}/?success=true&firstName=${details.firstName}&lastName=${details.lastName}&amount=${details.amount}&country=${details.country}&phone=${details.phone}&email=${details.email}&currency=${details.currency.value}`,
        customer: {
          email: details.email,
          phonenumber: details.phone,
          name: details.firstName,
        },
        customizations: {
          title: "NTYABA Donation",
          description: "One Dollar Donation Campaign",
          logo: "https://nowthatyouarebornagain.org/wp-content/uploads/2021/03/NTYABA-LOGO-small.png",
        },
    };

    const handleFlutterPayment = useFlutterwave(config);

    async function handleFlutterwave(){
        setBtnDisabled(true);

        handleFlutterPayment({
          callback: (response) => {
            console.log(response);
            setBtnDisabled(false);
            //  window.location.href = "/";
            closePaymentModal(); // this will close the modal programmatically
          },
          onClose: () => {
            setBtnDisabled(false);
          },
        });
      
    }

    const checkErrors = (action) => {
        const requiredKeys = Object.keys(requiredFieldErrors);

        const everyRequiredFieldHasValue = requiredKeys.every(key => details[key].length > 0);
        if (!everyRequiredFieldHasValue) {
            const foundMissingValIndex = requiredKeys.findIndex(key => details[key].length < 1);
            if (foundMissingValIndex === -1) return

            return swal("Info", requiredFieldErrors[`${requiredKeys[foundMissingValIndex]}`], "info");
        }

        if (!validateEmail(details.email)) return swal("Info", "Please enter a valid email", "info");

        if (action === 'now') {
            handleFlutterwave()
        }
        
        if (action === 'transfer') {
            setShow(true);
        }
    }
    
    if (pageLoading) return <>Please wait...</>
    if (!usernameFound) return <></>
    
    return (
      <>
        <Container className={styles.wrapper}>
          <div
            className={styles.wrapper}
            style={{ pointerEvents: btnDisabled ? "none" : "all" }}
          >
            <Row className={`${styles.row__Item} ${show ? styles.bank__Content : ''}`}>
              {
                show ? <>
                    <button
                        className={`${styles.btn} ${styles.go__Back}`}
                        onClick={
                            () => setShow(false)
                        }
                    >
                        Go back
                    </button>
                </> : <Col className={styles.left__Content} md={6}>
                    <img
                        src="https://nowthatyouarebornagain.org/sponsor/public/img/NTYABA012.png"
                        alt="one dollar"
                    />
                    <div className={styles.left__Content__Info}>
                        <h2>Make a Difference With Your Donation</h2>
                        <button
                            className={styles.btn}
                            onClick={() =>
                            (window.location =
                                "https://nowthatyouarebornagain.org")
                            }
                        >
                            Home
                        </button>
                    </div>
                </Col>
              }
              <Col className={!show ? styles.right__Content : ''} md={!show ? 6 : 12}>
                <img
                  className={styles.logo}
                  src="https://nowthatyouarebornagain.org/wp-content/uploads/2021/03/NTYABA-LOGO-small.png"
                  alt="logo"
                />
                <h3 style={{ marginBottom: "2rem" }}>
                    {
                        show ? 
                        "Bank Accounts" 
                        :
                        "Donate"
                    }</h3>
                {show ? (
                  <>
                    <Row>
                        <Table striped responsive>
                            <thead>
                                <tr>
                                    <th>Account type</th>
                                    <th>Bank Name</th>
                                    <th>Bank Address</th>
                                    <th>Account Name</th>
                                    <th>Account Number</th>
                                    <th>Routing Number</th>
                                    <th>Wire Transfer Domestic</th>
                                    <th>Sort Code</th>
                                    <th>IBAN</th>
                                    <th>SWIFT</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    React.Children.toArray(bankAccounts.map(account => {
                                        return <tr>
                                            <td>{account.type ? account.type : '-'}</td>
                                            <td>{account.bankName ? account.bankName : '-'}</td>
                                            <td>{account.address ? account.address : '-'}</td>
                                            <td>{account.accountName ? account.accountName : '-'}</td>
                                            <td>{account.accountNumber ? account.accountNumber : '-'}</td>
                                            <td>{account.routingNumber ? account.routingNumber : '-'}</td>
                                            <td>{account.wireTransferDomestic ? account.wireTransferDomestic : '-'}</td>
                                            <td>{account.sortCode ? account.sortCode : '-'}</td>
                                            <td>{account.iban ? account.iban : '-'}</td>
                                            <td>{account.swift ? account.swift : '-'}</td>
                                        </tr>
                                    }))
                                }
                            </tbody>
                        </Table>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="firstName">
                          <Form.Control
                            type="firstName"
                            value={details.firstName}
                            placeholder="First name"
                            name="firstName"
                            onChange={({ target }) =>
                              handleDetailChange(target.name, target.value)
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="lastName">
                          <Form.Control
                            type="lastName"
                            value={details.lastName}
                            placeholder="Last name"
                            name="lastName"
                            onChange={({ target }) =>
                              handleDetailChange(target.name, target.value)
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Form.Group controlId="email">
                      <Form.Control
                        type="email"
                        value={details.email}
                        placeholder="Email"
                        name="email"
                        onChange={({ target }) =>
                          handleDetailChange(target.name, target.value)
                        }
                      />
                    </Form.Group>

                    <Row>
                      <Col md={6} className={styles.country__Wrapper}>
                        <CountryDropdown
                          className="form-control"
                          value={details.country}
                          placeholder="Country"
                          name="country"
                          onChange={(val) => handleDetailChange("country", val)}
                        />
                      </Col>
                      <Col md={6}>
                        <PhoneInput
                          country={countryString}
                          inputStyle={{ width: "100%" }}
                          placeholder="Phone"
                          value={details.phone}
                          name="phone"
                          onChange={(val) => handleDetailChange("phone", val)}
                        />
                      </Col>
                    </Row>

                    <Form.Group
                      controlId="currency"
                      style={{
                        marginTop: "1rem",
                        // pointerEvents: 'none'
                      }}
                    >
                      <Select
                        value={details.currency}
                        onChange={(val) => handleDetailChange("currency", val)}
                        options={supportedCurrencies}
                        placeholder={"Currency"}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="amount">
                      <Form.Control
                        type="number"
                        value={details.amount}
                        placeholder="Amount"
                        name="amount"
                        onChange={({ target }) =>
                          handleDetailChange(target.name, target.value, true)
                        }
                      />
                    </Form.Group>

                    <Button
                      className="px-5 button"
                      style={{
                        backgroundColor: "#EC4D0F",
                        borderColor: "#EC4D0F",
                        filter: btnDisabled
                          ? "brightness(0.85)"
                          : "brightness(1)",
                      }}
                      onClick={() => checkErrors("now")}
                      disabled={btnDisabled}
                    >
                      Donate Now
                    </Button>

                    <Button
                      className="px-5 button"
                      style={{
                        backgroundColor: "#EC4D0F",
                        borderColor: "#EC4D0F",
                        filter: btnDisabled
                          ? "brightness(0.85)"
                          : "brightness(1)",
                      }}
                      onClick={() => setShow(true)}
                      disabled={btnDisabled}
                    >
                      Donate Now with Bank Transfer
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
}

const requiredFieldErrors = {
    firstName: 'Please enter your first name',
    lastName: 'Please enter your last name',
    email: 'Please enter your email',
    country: 'Please enter your country',
    phone: 'Please enter your phone number',
}

export default OneDollarDonationFormScreen;
